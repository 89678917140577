import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { ROUTES, TOKEN } from '../common/constants';
import Footer from '../common/footer/Footer';
import Header from '../common/header/Header';
import history from '../historyData';

const AuthWrapper = ({ component, ...rest }) => {
  // eslint-disable-next-line no-undef
  const isAuth = localStorage?.getItem(TOKEN);
  const { location } = history;
  return (
    <div className="page-flex">
      <Header />
      <div className="routes-wrapper">
        <Route
          {...rest}
          render={() =>
            isAuth ? (
              <>{component}</>
            ) : (
              <Redirect
                to={{
                  pathname: `${ROUTES?.LOGIN}`,
                  search: `path=${location?.pathname}`,
                }}
              />
            )
          }
        />
      </div>
      <Footer />
    </div>
  );
};

export default AuthWrapper;
