import { Skeleton } from 'antd';
import React, { useState } from 'react';

const Image = ({ src, alt, className }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [imageSrc, setImageSrc] = useState(src);

  const handleLoad = () => {
    setIsLoading(false);
  };
  const handleError = () => {
    setImageSrc('https://placehold.co/300x300/grey/grey');
  };

  return (
    <>
      {isLoading && <Skeleton.Image active />}
      {imageSrc && (
        <img
          src={imageSrc}
          alt={alt}
          className={className}
          onLoad={handleLoad}
          onError={handleError}
          style={{
            opacity: isLoading ? 0 : 1,
            transition: 'opacity 0.3s ease-in-out',
          }}
        />
      )}
    </>
  );
};

export default Image;
