import { Button, Result, message } from 'antd';
import { doc, setDoc, where } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { fetchDataWithWhere, handleError } from '../firebase/api';
import { usersRef } from '../firebase/collection';
import { MESSAGE, ROUTES } from './constants';
import sendEmail from './functions';

export default function RequestVerification() {
  const { state, dispatch } = useContext(AppContext);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const projectData = state?.userData?.project?.find(
    (obj) => id === obj?.projectId,
  );
  const isRequested = projectData?.isRequested;

  const handleAccess = async () => {
    const uid = state?.userData?.uid;
    const email = state?.userData?.email;
    const payload = state?.userData;
    const projects = state?.userData?.project ?? [];
    if (!projectData && id) {
      projects?.push({
        projectId: id,
        isRequested: true,
        isAllowed: false,
      });
    }
    try {
      const data = await fetchDataWithWhere(usersRef, where('uid', '==', uid));
      const userId = data?.[0]?.id;
      await setDoc(doc(usersRef, userId), {
        ...payload,
        project: projects,
      });
      await sendEmail(email);
      dispatch({
        type: 'SET_USER_DATA',
        data: { ...payload, project: projects },
      });
      message?.success(MESSAGE?.PERMISSION_REQUEST);
      return true;
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  const handleDashboardBtn = () => {
    history?.push(ROUTES?.DASHBOARD);
  };

  return (
    <div className="container">
      <Result
        status="warning"
        title={isRequested ? MESSAGE?.ACCESS_REQUESTED : MESSAGE?.NEED_ACCESS}
        extra={
          isRequested ? (
            <Button type="default" key="console" onClick={handleDashboardBtn}>
              Go back to Dashboard
            </Button>
          ) : (
            <Button
              type="default"
              key="console"
              onClick={handleAccess}
              loading={loading}
            >
              Request Permission
            </Button>
          )
        }
      />
    </div>
  );
}
