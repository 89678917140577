import { message } from 'antd';
import { doc, onSnapshot, query, setDoc, where } from 'firebase/firestore';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import CardsWrapper from '../../common/cards/CardsWrapper';
import SkeletonWrapper from '../../common/cards/SkeletonWrapper';
import { MESSAGE } from '../../common/constants';
import sendEmail from '../../common/functions';
import { fetchDataWithWhere } from '../../firebase/api';
import { demosRef, usersRef } from '../../firebase/collection';
import './index.scss';

export default function Dashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [dataList, setDataList] = useState(false);
  const { state, dispatch } = useContext(AppContext);
  const [project, setProject] = useState({});

  const fetchDataList = async () => {
    const data = await fetchDataWithWhere(
      demosRef,
      where('is_active', '==', true),
    );
    if (data) {
      setIsLoading(false);
      setDataList(data);
    }
  };

  const fetchRealTimeData = async (table, condition) => {
    try {
      setIsLoading(true);
      const searchQuery = query(table, condition);
      onSnapshot(searchQuery, (snapshot) => {
        snapshot?.docChanges()?.forEach((change) => {
          const data = change?.doc?.data();
          if (data) {
            const { project: projects } = data;
            fetchDataList();
            dispatch({ type: 'SET_USER_DATA', data });
            setProject(projects);
          }
        });
      });
    } catch (error) {
      setIsLoading(false);
      // eslint-disable-next-line no-console
      console?.error(error);
    }
  };

  const fetchUserData = async (uid) => {
    try {
      await fetchRealTimeData(usersRef, where('uid', '==', uid));
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleAccess = async (projectId) => {
    const uid = state?.userData?.uid;
    const email = state?.userData?.email;
    const payload = state?.userData;
    const projects = state?.userData?.project ? state?.userData?.project : [];
    const alreadyAddedProject = state?.userData?.project?.find(
      (obj) => projectId === obj?.projectId,
    );
    if (!alreadyAddedProject && projectId) {
      projects?.push({
        projectId,
        isRequested: true,
        isAllowed: false,
      });
    }
    try {
      const data = await fetchDataWithWhere(usersRef, where('uid', '==', uid));
      const id = data?.[0]?.id;
      await setDoc(doc(usersRef, id), {
        ...payload,
        project: projects,
      });
      await sendEmail(email);
      dispatch({
        type: 'SET_USER_DATA',
        data: { ...payload, project: projects },
      });
      message?.success(MESSAGE?.PERMISSION_REQUEST);
      return true;
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (state?.user?.uid) {
      fetchUserData(state?.user?.uid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.user]);

  return (
    <div className="custom-container demo-wrapper">
      {isLoading ? (
        <SkeletonWrapper />
      ) : (
        <CardsWrapper
          data={dataList}
          project={project}
          handleAccess={handleAccess}
        />
      )}
    </div>
  );
}
