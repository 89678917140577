import { LogoutOutlined } from '@ant-design/icons';
import { Avatar, Button, Space } from 'antd';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { AppContext } from '../../AppContext';
import { Logo } from '../../assets/svg';
import { handleLogout } from '../../firebase/api';
import { ROUTES } from '../constants';
import './index.scss';

export default function Header() {
  const { state } = useContext(AppContext);

  const handleLogoutBtn = () => {
    handleLogout();
  };

  return (
    <header className="custom-container">
      <Link to={ROUTES.DASHBOARD}>
        <Logo className="logo" />
      </Link>
      <Space>
        {state?.user?.uid && (
          <Button icon={<LogoutOutlined />} onClick={handleLogoutBtn}>
            Logout
          </Button>
        )}
        {state?.user?.photoURL ? (
          <Avatar src={state?.user?.photoURL} size="large" />
        ) : (
          <Button shape="circle" size="large">
            {state?.user?.displayName
              ?.split(' ')
              ?.map((word) => word?.[0])
              ?.join('')}
          </Button>
        )}
      </Space>
    </header>
  );
}
