import {
  CloseOutlined,
  DownOutlined,
  ExportOutlined,
  LeftOutlined,
  UpOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Collapse,
  Divider,
  Modal,
  Row,
  Space,
  Tag,
  Typography,
} from 'antd';
import React, { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom/cjs/react-router-dom';
import { AppContext } from '../../AppContext';
import Loader from '../../common/Loader';
import { ROUTES } from '../../common/constants';
import './index.scss';

export default function Projects() {
  const history = useHistory();
  const [isLoading] = useState(false);
  const { state } = useContext(AppContext);
  const [demoData] = useState(state?.demoData);
  const [iframeURL, setIframeURL] = useState();
  const expandRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);
  const [toggle, setToggle] = useState(true);
  const [linkDemoModal, setLinkDemoModal] = useState(false);

  const handlePreviousButton = () => {
    history?.push(ROUTES?.DASHBOARD);
  };

  const handleRedirection = () => {
    if (demoData?.render_type === 'link') {
      setLinkDemoModal(true);
    } else {
      if (toggle) {
        expandRef?.current?.click();
      }
      setIframeURL(demoData?.web_url);
      setIsVisible(false);
      // eslint-disable-next-line no-undef
      window?.scrollTo(0, 0);
    }
  };

  const CollapsableHeader = () => (
    <Space align="center" className="header-items" ref={expandRef}>
      <LeftOutlined className="back-arrow" onClick={handlePreviousButton} />
      {demoData?.logo_url && (
        <img
          src={demoData?.logo_url?.src}
          alt={demoData?.logo_url?.title}
          className="logo-img"
        />
      )}
      <Typography.Title level={2} className="text-header">
        {demoData?.title || '-'}
      </Typography.Title>
    </Space>
  );

  const CollapsableBody = () => (
    <>
      <Space direction="vertical" size={24} className="content-wrapper">
        <div>
          <Typography.Title level={3} className="text-title">
            {demoData?.title}
          </Typography.Title>
        </div>
        <div>
          <Typography.Title level={5} className="text-description">
            Description
          </Typography.Title>
          <Typography.Paragraph level={2} className="text-description">
            {demoData?.short_description || '-'}
          </Typography.Paragraph>
        </div>
        <div className="tags-collection">
          {Array?.isArray(demoData?.tags)
            ? demoData?.tags?.map((tag, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <Tag className="tags-wrapper" key={idx}>
                  {tag}
                </Tag>
              ))
            : demoData?.tags?.split(',')?.map((tag, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <Tag className="tags-wrapper" key={idx}>
                  {tag?.trim()}
                </Tag>
              ))}
        </div>
        {demoData?.long_description && (
          <div>
            <Typography.Title level={5} className="text-description">
              Detail Summary
            </Typography.Title>
            <p
              level={2}
              className="text-description"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: demoData?.long_description || '-',
              }}
            />
          </div>
        )}
        <Divider />
      </Space>
    </>
  );

  const items = [
    {
      key: '1',
      label: <CollapsableHeader />,
      children: <CollapsableBody />,
    },
  ];

  if (isLoading) {
    return <Loader />;
  }
  if (demoData?.is_active) {
    return (
      <div className="project-wrapper">
        <div className="custom-container">
          <div className="collpsable-item">
            <Collapse
              defaultActiveKey={['1']}
              ghost
              items={items}
              expandIconPosition="right"
              expandIcon={({ isActive }) => {
                setToggle(isActive);
                if (isActive) {
                  return <UpOutlined />;
                }
                return <DownOutlined />;
              }}
            />
          </div>
          {isVisible && (
            <div className="demo-section">
              <Row gutter={[132, 50]} justify="center">
                {demoData?.web_url && (
                  <Col xs={24} md={24} lg={12} className="demo-card">
                    <div className="card-wrapper">
                      {demoData?.logo_url && (
                        <img
                          src={demoData?.logo_url?.src}
                          alt={demoData?.logo_url?.title}
                          className="card-image"
                        />
                      )}
                      <Button
                        type="primary"
                        size="large"
                        onClick={handleRedirection}
                      >
                        Start Demo
                      </Button>
                    </div>
                  </Col>
                )}
                {demoData?.video_url && (
                  <Col xs={24} md={24} lg={12} className="demo-video">
                    <div className="demo-video-wrapper">
                      <iframe
                        src={demoData?.video_url}
                        allow="autoplay"
                        title={demoData?.title}
                        allowFullScreen="true"
                        className="project-iframe"
                      />
                    </div>
                  </Col>
                )}
              </Row>
            </div>
          )}
          {iframeURL && (
            <div className="website-iframe">
              <iframe
                src={iframeURL}
                allow="autoplay"
                title={demoData?.title}
                className="iframe"
              />
            </div>
          )}
        </div>
        <Modal
          open={linkDemoModal}
          footer={null}
          title={
            <Space className="modal-actions">
              <Button
                type="text"
                // eslint-disable-next-line no-undef
                onClick={() => window?.open(demoData?.web_url, '_blank')}
                icon={<ExportOutlined />}
              />
              <Button
                type="text"
                onClick={() => setLinkDemoModal(false)}
                icon={<CloseOutlined />}
              />
            </Space>
          }
          centered
          closable={false}
          wrapClassName="demo-modal-wrapper"
          className="demo-modal-container"
        >
          <iframe
            src={demoData?.web_url}
            allow="autoplay"
            title={demoData?.title}
            className="demo-iframe"
          />
        </Modal>
      </div>
    );
  }
  return <Redirect to={ROUTES?.PROJECT_NOT_ACTIVE} />;
}
