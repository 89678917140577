import { LockOutlined, StarFilled } from '@ant-design/icons';
import { Button, Card, Divider, Modal, Space, Tag, Typography } from 'antd';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Image from '../Image';
import { COLOR_PALLETS, ROUTES } from '../constants';
import './index.scss';

const { Meta } = Card;

export default function CustomCard({ item, project, handleAccess }) {
  const {
    tags,
    short_description: description,
    title,
    id,
    thumbnail_url: imgUrl,
    logo_url: logoUrl,
    is_private: isPrivate,
    is_featured: isFeatured,
  } = item;

  const projectData = project?.find((obj) => id === obj?.projectId);
  const checkIsValid = useMemo(
    () => ({
      isAllowed: projectData?.isAllowed || false,
      isRequest: projectData?.isRequested || false,
    }),
    [projectData?.isAllowed, projectData?.isRequested],
  );
  const adminAllowance =
    isPrivate && checkIsValid?.isAllowed ? false : isPrivate;
  const isDemoPrivate =
    isPrivate && checkIsValid?.isRequest && checkIsValid?.isAllowed
      ? false
      : adminAllowance;

  const [openAccessModal, setOpenAccessModal] = useState(false);
  const [isRequested, setIsRequested] = useState(
    (isPrivate && !!(checkIsValid?.isRequest && !checkIsValid?.isAllowed)) ||
      false,
  );
  const history = useHistory();

  const CardFooter = () => (
    <Space>
      <Space size={[0, 8]} wrap direction="vertical">
        <Space className="title-wrapper" direction="vertical">
          <Space align="center" direction="horizontal" className="logo-wrapper">
            {logoUrl && (
              <img
                src={logoUrl?.src}
                alt={logoUrl?.title}
                className="logo-img"
              />
            )}
            <Typography.Title level={3} className="text-title">
              {title}
            </Typography.Title>
          </Space>
          <Space wrap>
            {Array?.isArray(tags)
              ? tags?.map((tag, idx) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Tag className="tags-wrapper" key={idx}>
                    {tag}
                  </Tag>
                ))
              : tags?.split(',')?.map((tag, idx) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Tag className="tags-wrapper" key={idx}>
                    {tag?.trim()}
                  </Tag>
                ))}
          </Space>
        </Space>
        <Typography.Paragraph
          className="text-description"
          ellipsis={{ rows: 3 }}
        >
          {description}
        </Typography.Paragraph>
      </Space>
    </Space>
  );
  const handleCardButton = () => {
    if (!isDemoPrivate) {
      history?.push(`${ROUTES?.PROJECTS}/${id}`);
    } else if (checkIsValid?.isRequest) {
      setIsRequested(true);
    } else {
      setOpenAccessModal(true);
    }
  };

  const handleOkButton = () => {
    handleAccess(id);
    setOpenAccessModal(false);
    setIsRequested(true);
  };

  if (isFeatured) {
    return (
      <>
        <Card
          className={
            isDemoPrivate
              ? 'private-card-cover featured-card custom-card'
              : 'featured-card custom-card'
          }
          onClick={handleCardButton}
          cover={
            <>
              <div className="image">
                <StarFilled
                  className="float-star"
                  style={{ color: COLOR_PALLETS?.SUNSET_ORANGE }}
                />
                {imgUrl && (
                  <Image
                    src={imgUrl?.src}
                    alt={imgUrl?.title}
                    className="card-image"
                  />
                )}
              </div>
              <div className={isDemoPrivate || isRequested ? 'overlay' : ''}>
                {(isRequested && (
                  <div className="request-text">
                    <h3>Access Requested</h3>
                  </div>
                )) ||
                  (isDemoPrivate && (
                    <Button
                      type="primary"
                      shape="circle"
                      className="float-lock"
                      icon={<LockOutlined />}
                    />
                  ))}
              </div>
            </>
          }
        >
          <Meta description={<CardFooter />} />
        </Card>
        <Modal
          title={title || 'Access Modal'}
          open={openAccessModal}
          onOk={handleOkButton}
          okText="Request Access"
          onCancel={() => setOpenAccessModal(false)}
          // using inline style to hide cancel button
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <Divider />
          <Typography.Title level={4}>This is a private demo</Typography.Title>
          <Typography.Paragraph>
            Request access to view this demo
          </Typography.Paragraph>
          <Divider />
        </Modal>
      </>
    );
  }
  return (
    <>
      <Card
        className={
          isDemoPrivate ? 'private-card-cover custom-card' : 'custom-card'
        }
        onClick={handleCardButton}
        cover={
          <>
            <div className="image">
              {imgUrl && (
                <Image
                  src={imgUrl?.src}
                  alt={imgUrl?.title}
                  className="card-image"
                />
              )}
            </div>
            <div className={isDemoPrivate || isRequested ? 'overlay' : ''}>
              {(isRequested && (
                <div className="request-text">
                  <h3>Access Requested</h3>
                </div>
              )) ||
                (isDemoPrivate && (
                  <Button
                    type="primary"
                    shape="circle"
                    className="float-lock"
                    icon={<LockOutlined />}
                  />
                ))}
            </div>
          </>
        }
      >
        <Meta description={<CardFooter />} />
      </Card>
      <Modal
        title={title || 'Access Modal'}
        open={openAccessModal}
        onOk={handleOkButton}
        okText="Request Access"
        onCancel={() => setOpenAccessModal(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <Divider />
        <Typography.Title level={4}>This is a private demo</Typography.Title>
        <Typography.Paragraph>
          Request access to view this demo
        </Typography.Paragraph>
        <Divider />
      </Modal>
    </>
  );
}
