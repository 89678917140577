import { Col, Row, Typography } from 'antd';
import React from 'react';
import './index.scss';

export default function Footer() {
  return (
    <div className="footer-wrapper">
      <div className="custom-container">
        <Row gutter={[24, 24]}>
          <Col xs={24} lg={12}>
            <div className="about-us-wrapper">
              <Typography.Title level={3} className="text-header">
                About us
              </Typography.Title>
              <Typography.Paragraph className="text-description">
                We are a passionate team of over 130+ technologists, dedicated
                to crafting aesthetically pleasing, seamlessly functioning
                software.
              </Typography.Paragraph>
            </div>
          </Col>
          <Col xs={24} lg={12}>
            <div className="contact-us-wrapper">
              <Typography.Title level={3} className="text-header">
                Visit us:
                <Typography.Link
                  href="https://www.logicwind.com/"
                  target="_blank"
                >
                  www.logicwind.com
                </Typography.Link>
              </Typography.Title>
              <Typography.Paragraph className="text-description">
                © {new Date()?.getFullYear()} Logicwind - We build platforms
                that scale fast
              </Typography.Paragraph>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
