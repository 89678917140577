import { Button, Image, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import loginImage from '../assets/images/sign-in.png';
import { Google } from '../assets/svg';
import { ROUTES, TOKEN } from '../common/constants';
import { handleLogin } from '../firebase/api';
import './index.scss';

const { Text } = Typography;

export default function Login() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const pathName = searchParams.get('path');
  const [loading, setLoading] = useState();
  const history = useHistory();

  const handleLoading = (toggle) => {
    setLoading(toggle);
  };

  const handleGoogleSignIn = async () => {
    if (pathName) {
      handleLogin(handleLoading, pathName);
    } else {
      handleLogin(handleLoading, ROUTES?.DASHBOARD);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const accessToken = localStorage?.getItem(TOKEN);
    if (accessToken) history?.replace(ROUTES?.DASHBOARD);
  });

  return (
    <div className="login-wrapper">
      <div className="login-form">
        <div className="form-content">
          <div>
            <h1>Welcome</h1>
            <p>Crafting Digital Products Mindfully.</p>
          </div>
          <Button
            icon={<Google />}
            variant="default"
            color="gray"
            className="btn-login"
            size="large"
            onClick={handleGoogleSignIn}
            loading={loading}
          >
            <Text className="text-login" strong>
              Continue with Google
            </Text>
          </Button>
        </div>
      </div>
      <div className="cover-image">
        <Image
          preview={false}
          className="side-img"
          src={loginImage}
          alt="login-side-image"
        />
      </div>
    </div>
  );
}
